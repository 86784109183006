jQuery.noConflict();
jQuery(document).ready(function() {
	jQuery('.burger, .overlay').click(function(){
    jQuery('.burger').toggleClass('clicked');
    //$('.overlay').toggleClass('show');
    jQuery('.menusmart').toggleClass('show');
    jQuery('body').toggleClass('overflow');


    /* MENU SMART */
    /*jQuery('.menusmart li.menu-item-has-children .sub-menu').hide();
    jQuery(".menusmart li.menu-item-has-children").click(function(e){
      //e.preventDefault();
    //
    if (! jQuery(this).hasClass('act')) {
      jQuery('.menusmart li.menu-item-has-children').removeClass('act');
      jQuery('.menusmart li.menu-item-has-children .sub-menu').slideUp();
      jQuery(this).addClass("act").find('.sub-menu').slideToggle();
    }
      //return false;
    });  */


	});


	/* ========================================================================= */
	/*	Header Scroll Background Change
	/* ========================================================================= */
  
	jQuery(window).scroll(function () {
        var windowTop = jQuery(window).scrollTop();
        if(jQuery('#header').length){
          
          if (windowTop > 0) {
            jQuery('body').addClass("sticky");
          } else{
            jQuery('body').removeClass("sticky");
          }
        }
        if(jQuery('#headersmart').length){
          if (windowTop > 20) {
            jQuery('#headersmart').addClass("sticky");
          } else{
            jQuery('#headersmart').removeClass("sticky");
          }
        }
  
    
    
      });

    function repositionElementB() {
      if (jQuery(window).width() < 992) {
      var elementAPos = jQuery('.headnvid').position();
      var heightHeadVid = jQuery('.headnvid').outerHeight();
      
      var pos = ((heightHeadVid -  jQuery('.bandeaun__text').outerHeight()) / 2) - (jQuery('.headvh__titsvg').outerHeight()/2);
      //alert(heightHeadVid + " " + pos);
      jQuery('.headvh__titsvg').css({
          'top': pos + 'px',
          'left': elementAPos.left + 'px'
      });
    }
    }
    if (jQuery(window).width() < 992) {
      repositionElementB();
  }

    jQuery(window).resize(function() {
      if (jQuery(window).width() < 992) {
          repositionElementB();
      }
  });    
});